import React from "react";
import { useMutation } from "@apollo/client";
import { M_GET_SYMPTOMS, M_RECORD_SYMPTOMS } from "../data/queries/symptoms";
import { Box, Button, CircularProgress, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ICONS } from "./Icons/IconComponentMapping";
import BIcon from "./Icons/IconComponent";
import DeviceDetails, { IPatientDetails } from "../types/IPatientDetails";
import messages from "./../data/UserMessages.json";
import { APP_ROUTES } from "../constants/appRoutes";
import IconLeftArrow from "./Icons/SVGs/IconLeftArrow";
import { generateID } from "./utilityFunctions";

const PatientSymptoms = () => {
  const device = React.useContext<IPatientDetails>(DeviceDetails);
  const navigate = useNavigate();
  const [data, loading] = useMutation(M_GET_SYMPTOMS);
  const [apiRecordSymptoms] = useMutation(M_RECORD_SYMPTOMS);
  const [symptomsList, setSymptomsList] = React.useState(null);
  const [selectedSymptoms, setSelectedSymptoms] = React.useState([]);

  const captureSymptoms = symCode => {
    if (selectedSymptoms.includes(symCode)) {
      // remove from list
      const orgSymptomArray = [...selectedSymptoms];

      for (let del = 0; del < orgSymptomArray.length; del++) {
        if (orgSymptomArray[del] === symCode) {
          orgSymptomArray.splice(del, 1);
          break;
        }
      }
      setSelectedSymptoms(() => orgSymptomArray);
    } else {
      // add to list
      setSelectedSymptoms([...selectedSymptoms, symCode]);
    }
  };

  React.useEffect(() => {
    data().then(res => {
      setSymptomsList(
        res?.data?.getSymptoms?.sort((a, b) => a.displayOrder - b.displayOrder)
      );
    });
  }, []);

  const recordSymptoms = () => {
    const input = {
      input: {
        deviceId: device?.deviceId,
        symptomNames: selectedSymptoms,
      },
    };
    apiRecordSymptoms({ variables: input }).then(res => {
      res.data.recordSymptoms
        ? navigate(`/${APP_ROUTES.MESSAGE}`, {
            state: {
              type: "success",
              messageText: "Your symptoms have been recorded",
              nextRoute: `${APP_ROUTES.ROOT}`,
            },
          })
        : navigate(`/${APP_ROUTES.MESSAGE}`, {
            state: {
              type: "failure",
              messageText: "Unable to send out your symptoms",
              nextRoute: `${APP_ROUTES.ROOT}`,
            },
          });
    });
  };

  return (
    <>
      <Grid item padding="24px 0 0 0" className="sticky-header">
        <Button onClick={() => navigate(-1)}>
          <BIcon
            icon={IconLeftArrow}
            style={{ height: "24px", width: "24px" }}
          />
        </Button>
      </Grid>
      <Grid item className="page-header sticky-header" sx={{ top: "68px" }}>
        {messages.recordSymptoms.txtSelectSymptoms}
      </Grid>
      {loading?.loading ? (
        <div
          style={{
            color: "#0C80A1",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress color="inherit" />
        </div>
      ) : symptomsList && symptomsList.length > 0 ? (
        <React.Fragment>
          <div className="patient-symptoms-main">
            <header className="patient-symptoms-container">
              {symptomsList.map((dataItem, symptomIndex) => {
                return (
                  <Button
                    key={symptomIndex}
                    style={{ display: "flex", alignItems: "center" }}
                    className={
                      selectedSymptoms.includes(dataItem.name)
                        ? "selected"
                        : "notselected"
                    }
                    onClick={() => {
                      captureSymptoms(dataItem.name);
                    }}
                  >
                    {selectedSymptoms.includes(dataItem.name) ? (
                      <>
                        <BIcon
                          icon={ICONS["IconCheck"]}
                          style={{ margin: "0px", display: "flex" }}
                        />
                      </>
                    ) : null}
                    {dataItem.displayName}
                  </Button>
                );
              })}
            </header>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              id={generateID("btn", "patientsymptoms", "record")}
              disabled={selectedSymptoms.length === 0}
              variant="outlined"
              sx={{ width: "80%", margin: "16px 0" }}
              className="baxi-button baxi-primary-button"
              onClick={() => {
                recordSymptoms();
              }}
            >
              {messages.recordSymptoms.btnRecordSymptoms}
            </Button>
          </div>
        </React.Fragment>
      ) : (
        <p style={{ marginLeft: "24px" }}>
          {messages.recordSymptoms.txtNoSymptomsAvailable}
        </p>
      )}
    </>
  );
};

export default PatientSymptoms;
